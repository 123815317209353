
import { defineComponent } from "vue";
import NavigationLinks from "@/components/layout/NavigationLinks.vue";
import UserInfo from "@/components/layout/UserInfo.vue";

export default defineComponent({
    components: {
        "user-info": UserInfo,
        "navigation-links": NavigationLinks
    }
});
