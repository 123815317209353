import { MenuItem } from "@/models";
import { routePaths } from "@/router/routePaths";

export const menuItems: Array<MenuItem> = [
    {
        name: "Cancellation Blackout Dates",
        path: routePaths.cancellationBlackoutDatePath,
        icon: "ban"
    },
    {
        name: "Community Image Management",
        path: routePaths.communityGalleryImageManagementPath,
        icon: "images"
    },
    {
        name: "Course Management",
        path: routePaths.courseManagementPath,
        icon: "school"
    },
    {
        name: "Email Template Management",
        path: routePaths.emailTemplateManagementPath,
        icon: "envelope-open-text"
    },
    {
        name: "Emails Sent",
        path: routePaths.emailsSentPath,
        icon: "mail-bulk"
    },
    {
        name: "Progress Level Management",
        path: routePaths.progressLevelPath,
        icon: "chart-line"
    }
];
