
import { defineComponent, watch } from "vue";
import { useRouter } from "vue-router";
import { routeNames } from "@/router/routeNames";
import { AppGetterTypes, useStore } from "@/store";

export default defineComponent({
    name: "home",
    setup() {
        const { getters } = useStore();
        const { push } = useRouter();

        watch(
            () => getters[AppGetterTypes.signedIn],
            () => {
                if (getters.signedIn) {
                    push({ name: routeNames.courseManagement });
                }
            },
            { immediate: true }
        );
    }
});
