
import { computed, defineComponent, onMounted } from "vue";
import { GetterTree } from "vuex";
import { msalService } from "@/authentication";
import { AppGetterTypes, AppState, useStore } from "@/store";

export default defineComponent({
    name: "user-info",
    setup() {
        const store = useStore();
        const getters: GetterTree<AppState, AppState> = store.getters;

        onMounted(async () => {
            // try to get the account from MSAL/localStorage
            await msalService.getAccount();
        });

        return {
            fullName: computed(() => getters[AppGetterTypes.fullName]),
            signedIn: computed(() => getters[AppGetterTypes.signedIn]),
            signIn: (): Promise<void> => msalService.signIn(),
            signOut: (): Promise<void> => msalService.signOut()
        };
    }
});
