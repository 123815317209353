import { getSettings, setAccessToken, setAccount } from "@/store";
import { HandleSignInRedirect } from "@elite/msal";

/**
 * Handle the AAD sign in redirect flow.
 * see https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/FAQ.md#i-dont-understand-the-redirect-flow-how-does-the-handleredirectpromise-function-work
 */
export const handleSignInRedirect: HandleSignInRedirect = (
    account,
    accessToken
): void => {
    if (accessToken) {
        setAccessToken(accessToken);
        getSettings();
    }

    if (account) {
        setAccount(account);
    }
};
