
import { defineComponent } from "@vue/runtime-core";
import { menuItems } from "./menu";
import NavigationLinkContent from "./NavigationLinkContent.vue";

export default defineComponent({
    name: "navigation-links",
    components: {
        "navigation-link-content": NavigationLinkContent
    },
    setup() {
        return {
            menuItems
        };
    }
});
