import { Policy, PolicyConfiguration } from "@elite/msal";

const scopes: string[] = process.env.VUE_APP_API_SCOPES?.split(" ");

const signUpSignIn: Policy = {
    authority: process.env.VUE_APP_AAD_AUTHORITY,
    scopes: scopes
};

const editProfile: Policy = {
    authority: process.env.VUE_APP_AAD_EDIT_PROFILE_AUTHORITY,
    scopes: scopes
};

const resetPassword: Policy = {
    authority: process.env.VUE_APP_AAD_RESET_PASSWORD_AUTHORITY,
    scopes: scopes
};

export const policyConfig: PolicyConfiguration = {
    signUpSignIn,
    editProfile,
    resetPassword
};
