import { defineRule } from "vee-validate";

import { email, length, max, min, regex, required } from "@vee-validate/rules";

export function configureValidation(): void {
    defineRule("required", required);
    defineRule("email", email);
    defineRule("length", length);
    defineRule("regex", regex);
    defineRule("max", max);
    defineRule("min_value", min);

    defineRule("date", (value: string) => {
        if (!value) {
            return true;
        }

        // this regex ensures the string must be from 01 to 12, then a /, then be from 01 to 31, then a /, then be between 1900 and 2099
        const dateRegex =
            /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d\d$/;
        if (!dateRegex.test(value)) {
            return false;
        }

        const date = new Date(value);
        // converts date into MM/dd/yyyy for string comparison (04/31 doesn't exist and will become 05/01)
        const dateStr = `${(date.getMonth() + 1)
            .toString()
            .padStart(2, "0")}/${date
            .getDate()
            .toString()
            .padStart(2, "0")}/${date.getFullYear()}`;
        return dateStr === value;
    });

    // dummy validators for display purposes
    defineRule("displayName", () => true);
    defineRule("propertyName", () => true);
}
