export const routeNames = {
    home: "Home",
    communityGalleryImage: "Community Image Management",
    createCommunityGalleryImage: "Create Community Image",
    editCommunityGalleryImage: "Edit Community Image",
    courseManagement: "Course Management",
    createCourse: "Create Course",
    editCourse: "Edit Course",
    progressLevel: "Progress Level Management",
    editProgressLevel: "Edit Progress Level",
    createProgressLevel: "Create Progress Level",
    createLesson: "Create Lesson",
    editLesson: "Edit Lesson",
    createCancellationOffer: "Create Cancel/Save Offer",
    editCancellationOffer: "Edit Cancel/Save Offer",
    cancellationOfferPreview: "Save/Cancel Offer Preview",
    createQuiz: "Create Quiz",
    editQuiz: "Edit Quiz",
    emailTemplateManagement: "Email Template Management",
    editEmailTemplate: "Edit Email Template",
    emailsSent: "Emails Sent",
    cancellationBlackoutDate: "Cancellation Blackout Dates",
    editCancellationBlackoutDate: "Edit Cancellation Blackout Date",
    createCancellationBlackoutDate: "Create Cancellation Blackout Date"
};
