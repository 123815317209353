import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "list-group list-group-flush" }
const _hoisted_2 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_navigation_link_content = _resolveComponent("navigation-link-content")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuItems, (menuItem) => {
      return (_openBlock(), _createElementBlock("div", {
        key: menuItem.name
      }, [
        (menuItem.external)
          ? (_openBlock(), _createElementBlock("a", {
              key: 0,
              class: "list-group-item list-group-item-action list-group-item-transparent",
              href: menuItem.path,
              target: "_blank"
            }, [
              _createVNode(_component_navigation_link_content, { "menu-item": menuItem }, null, 8, ["menu-item"])
            ], 8, _hoisted_2))
          : (_openBlock(), _createBlock(_component_router_link, {
              key: 1,
              class: "list-group-item list-group-item-action list-group-item-transparent",
              to: menuItem.path
            }, {
              default: _withCtx(() => [
                _createVNode(_component_navigation_link_content, { "menu-item": menuItem }, null, 8, ["menu-item"])
              ]),
              _: 2
            }, 1032, ["to"]))
      ]))
    }), 128))
  ]))
}