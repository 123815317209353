import { InjectionKey } from "vue";
import { createStore, Store, useStore as baseUseStore } from "vuex";

import { AccountInfo } from "@azure/msal-common";

import { actions, AppActionTypes, restClient } from "./actions";
import { AppState } from "./AppState";
import { AppGetterTypes, getters } from "./getters";
import { AppMutationTypes, mutations } from "./mutations";

const fontSizes = [];
for (let i = 8; i <= 48; i = i + 2) {
    fontSizes.push(`${i}pt`);
}

const state: AppState = {
    account: null,
    accessToken: null,
    settings: null,
    user: null,
    version: process.env.VUE_APP_VERSION || "1.0.0",
    gridState: {},
    fontSizes: fontSizes
};

// strongly typed useStore - see https://next.vuex.vuejs.org/guide/typescript-support.html#typing-usestore-composition-function

export const storeKey: InjectionKey<Store<AppState>> = Symbol();

export function useStore(): Store<AppState> {
    return baseUseStore(storeKey);
}

export const store = createStore<AppState>({
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
    modules: {}
});

/**
 * Sets the account info in the store
 * @param account the user account
 */
export function setAccount(account: AccountInfo | null): void {
    store.commit(AppMutationTypes.setAccount, account);

    store.dispatch(AppActionTypes.getUser);
}

/**
 * Sets the access token in the store and in the axios client authorization header
 * @param accessToken the access token
 */
export function setAccessToken(accessToken: string | null): void {
    store.commit(AppMutationTypes.setAccessToken, accessToken);

    if (accessToken) {
        restClient.setAuthorizationHeader(accessToken);
    }
}

/**
 * Gets the settings after an access token has been acquired.
 */
export function getSettings(): void {
    store.dispatch(AppActionTypes.getSettings);
}

export { AppState, AppMutationTypes, AppGetterTypes, AppActionTypes };
