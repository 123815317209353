import { ApiGridState, DataSourceResult } from "@/models";
import {
    GridFilterChangeEvent,
    GridPageChangeEvent,
    GridSortChangeEvent
} from "@progress/kendo-vue-grid";
import { MutationTree } from "vuex";

import { AppState } from "./AppState";

export const AppMutationTypes = {
    setAccount: "setAccount",
    setAccessToken: "setAccessToken",
    setSettings: "setSettings",
    setUser: "setUser",
    setGridDataItems: "setGridDataItems",
    setGridState: "setGridState",
    setGridPage: "setGridPage",
    setGridSort: "setGridSort",
    setGridFilter: "setGridFilter"
};

export const mutations: MutationTree<AppState> = {
    [AppMutationTypes.setAccount](state, account) {
        state.account = account;
    },
    [AppMutationTypes.setAccessToken](state, accessToken) {
        state.accessToken = accessToken;
    },
    [AppMutationTypes.setSettings](state, settings) {
        state.settings = settings;
    },
    [AppMutationTypes.setUser](state, user) {
        state.user = user;
    },

    [AppMutationTypes.setGridDataItems](
        state,
        gridIdResult: { gridId: string; result: DataSourceResult }
    ) {
        state.gridState[gridIdResult.gridId].dataItems =
            gridIdResult.result.data || [];

        state.gridState[gridIdResult.gridId].total =
            gridIdResult.result.total || 0;
    },

    [AppMutationTypes.setGridState](state, gridState: ApiGridState) {
        state.gridState[gridState.id] = gridState;
    },

    [AppMutationTypes.setGridPage](
        state,
        idEvent: { id: string; event: GridPageChangeEvent }
    ) {
        state.gridState[idEvent.id].skip = idEvent.event.page.skip;
        state.gridState[idEvent.id].take = idEvent.event.page.take;
    },

    [AppMutationTypes.setGridSort](
        state,
        idEvent: { id: string; event: GridSortChangeEvent }
    ) {
        state.gridState[idEvent.id].sort = idEvent.event.sort;
    },

    [AppMutationTypes.setGridFilter](
        state,
        idEvent: { id: string; event: GridFilterChangeEvent }
    ) {
        state.gridState[idEvent.id].filter = idEvent.event.filter;
    }
};
