export const routePaths = {
    courseManagementPath: "/course-management",
    progressLevelPath: "/progress-level",
    emailTemplateManagementPath: "/email-template-management",
    emailsSentPath: "/emails-sent",
    cancellationBlackoutDatePath: "/cancellation-blackout-dates",
    createCancellationBlackoutDatePath: "/cancellation-blackout-date/create",
    editCancellationBlackoutDatePath:
        "/cancellation-blackout-date/edit/:cancellationBlackoutDateId",
    createCoursePath: "/course/create",
    editCoursePath: "/course/edit/:courseId",
    createLessonPath: "/course/:courseId/lesson/create",
    editLessonPath: "/course/:courseId/lesson/edit/:lessonId",
    createCancellationOfferPath: "/course/:courseId/cancellation-offer/create",
    editCancellationOfferPath:
        "/course/:courseId/cancellation-offer/edit/:offerId",
    cancellationOfferPreviewPath:
        "/course/:courseId/cancellation-offer/edit/:offerId/preview/",
    createProgressLevelPath: "/progress-level/create",
    editProgressLevelPath: "/progress-level/edit/:progressLevelId",
    createQuizPath: "/course/:courseId/lesson/:lessonId/quiz/create",
    editQuizPath: "/course/:courseId/lesson/:lessonId/quiz/edit/:quizId",
    editEmailTemplatePath: "/email-template/edit/:id",
    communityGalleryImageManagementPath: "/community-image-management",
    createCommunityGalleryImageManagementPath:
        "/community-image-management/create",
    editCommunityGalleryImageManagementPath:
        "/community-image-management/edit/:communityGalleryImageId"
};
