import { App } from "vue";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faAngleDown,
    faAngleRight,
    faBars,
    faCheckCircle,
    faCircle,
    faEnvelopeOpenText,
    faMailBulk,
    faPen,
    faPlus,
    faSchool,
    faSignInAlt,
    faSignOutAlt,
    faTimesCircle,
    faUserCog,
    faInfoCircle,
    faChartLine,
    faImages,
    faBan
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export function useFontAwesome(app: App): App {
    library.add(
        faSignInAlt,
        faSignOutAlt,
        faBars,
        faAngleRight,
        faAngleDown,
        faCircle,
        faUserCog,
        faSchool,
        faEnvelopeOpenText,
        faMailBulk,
        faPlus,
        faPen,
        faTimesCircle,
        faCheckCircle,
        faInfoCircle,
        faChartLine,
        faImages,
        faBan
    );

    return app.component("font-awesome-icon", FontAwesomeIcon);
}
