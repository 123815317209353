
import { defineComponent } from "vue";
import { footerMenuItems } from "./footer-menu";

export default defineComponent({
    data() {
        return {
            menuItems: footerMenuItems
        };
    }
});
