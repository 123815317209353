
import { computed, defineComponent, watch } from "vue";
import { setRaygunUser } from "@elite/vue-logging";
import { AppGetterTypes, store, useStore } from "./store";
import { UserModel } from "./models";

import "@progress/kendo-theme-bootstrap/dist/all.css";

import MobileOffCanvasNavigation from "./components/layout/MobileOffCanvasNavigation.vue";
import PrimaryBanner from "./components/layout/PrimaryBanner.vue";
import PrimaryFooter from "./components/layout/PrimaryFooter.vue";
import SidebarNavigation from "./components/layout/SidebarNavigation.vue";

export default defineComponent({
    components: {
        "sidebar-navigation": SidebarNavigation,
        "primary-banner": PrimaryBanner,
        "mobile-off-canvas-navigation": MobileOffCanvasNavigation,
        "primary-footer": PrimaryFooter
    },
    setup() {
        const { state } = useStore();

        // tell raygun about the logged in user, once they are logged in
        watch(
            () => state.user,
            (user: UserModel | undefined) => {
                if (user && user.id) {
                    const userInfo = {
                        identifier: user.id.toString(),
                        isAnonymous: false,
                        email: user.email,
                        firstName: user.firstName || user.name,
                        fullName:
                            (user.firstName || user.name || "") +
                            " " +
                            (user.lastName || user.familyName || "")
                    };
                    setRaygunUser(userInfo);
                }
            },
            { immediate: true }
        );

        return {
            signedIn: computed(() => store.getters[AppGetterTypes.signedIn]),
            isAuthorized: computed(
                () => store.getters[AppGetterTypes.isAuthorized]
            )
        };
    }
});
