
import { computed, defineComponent } from "vue";
import { RouteRecordRaw, useRoute } from "vue-router";

export default defineComponent({
    name: "primary-banner",
    setup() {
        const route = useRoute();

        return {
            title: computed(() => route.name),
            routeParams: computed(() => route.params),
            breadcrumbs: computed(
                () =>
                    (route.meta.breadcrumbs ||
                        []) as unknown as RouteRecordRaw[]
            )
        };
    }
});
