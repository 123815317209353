
import { computed, defineComponent } from "vue";
import NavigationLinks from "@/components/layout/NavigationLinks.vue";
import UserInfo from "@/components/layout/UserInfo.vue";
import { AppGetterTypes, useStore } from "@/store";

export default defineComponent({
    name: "sidebar-navigation",
    components: {
        "user-info": UserInfo,
        "navigation-links": NavigationLinks
    },
    setup() {
        const store = useStore();
        return {
            version: store.state.version,
            isAuthorized: computed(
                () =>
                    store.getters[AppGetterTypes.signedIn] &&
                    store.getters[AppGetterTypes.isAuthorized]
            )
        };
    }
});
