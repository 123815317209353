import { ActionTree } from "vuex";

import {
    AdminSettingsModel,
    ApiGridState,
    DataSourceResult,
    UserModel
} from "@/models";
import { RestClient } from "@elite/restclient";

import { AppState } from "./AppState";
import { AppMutationTypes } from "./mutations";

import { toDataSourceRequestString } from "@progress/kendo-data-query";

export const AppActionTypes = {
    getUser: "getUser",
    getSettings: "getSettings",
    getGridData: "getGridData",
    refreshGrid: "refreshGrid"
};

export const restClient = new RestClient();
restClient.client.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;

async function fetchGridData(
    gridState: ApiGridState
): Promise<DataSourceResult> {
    const request = toDataSourceRequestString(gridState);

    const response = await restClient.postJson<DataSourceResult>(
        `${gridState.url}?${request}`,
        gridState.requestBody || {}
    );

    if (!response.data) {
        throw new Error("No data returned");
    }

    return response.data;
}

export const actions: ActionTree<AppState, AppState> = {
    [AppActionTypes.getUser]: async (context) => {
        const response = await restClient.getJson<UserModel>("/api/admin/User");

        context.commit(AppMutationTypes.setUser, response.data);

        return response.data;
    },

    [AppActionTypes.getSettings]: async (context) => {
        const response = await restClient.getJson<AdminSettingsModel>(
            "/api/admin/Setting"
        );

        context.commit(AppMutationTypes.setSettings, response.data);

        return response.data;
    },

    [AppActionTypes.getGridData]: async (
        context,
        gridState: ApiGridState
    ): Promise<DataSourceResult | null> => {
        if (!gridState || !gridState.url) {
            throw new Error("No URL provided for grid state.");
        }

        context.commit(AppMutationTypes.setGridState, gridState);
        return await context.dispatch(AppActionTypes.refreshGrid, gridState.id);
    },

    [AppActionTypes.refreshGrid]: async (
        context,
        gridId: string
    ): Promise<DataSourceResult | null> => {
        // If there isn't a grid state for the grid ID, throw an error
        if (!context.state.gridState[gridId]) {
            throw new Error(`No grid state found for grid ID: ${gridId}`);
        }

        // Get the grid data
        const dataSourceResult = await fetchGridData(
            context.state.gridState[gridId]
        );

        // Is the response is empty, return null.
        if (!dataSourceResult.data) {
            return null;
        }

        // Set the data source result for the grid
        context.commit(AppMutationTypes.setGridDataItems, {
            gridId,
            result: dataSourceResult
        });

        return dataSourceResult;
    }
};
