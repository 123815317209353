import { GetterTree } from "vuex";

import { AppState } from "./AppState";

export const AppGetterTypes = {
    userName: "userName",
    fullName: "fullName",
    signedIn: "signedIn",
    isAuthorized: "isAuthorized",
    validationRules: "validationRules",
    emailTemplateTags: "emailTemplateTags",
    fileUploadTypes: "fileUploadTypes"
};

export const getters: GetterTree<AppState, AppState> = {
    [AppGetterTypes.userName]: (state: AppState) => {
        if (state.account?.username) {
            return state.account?.username;
        }

        return "";
    },

    [AppGetterTypes.fullName]: (state: AppState) => {
        const firstName = state.user?.firstName || "";
        const lastName = state.user?.lastName || "";
        return `${firstName} ${lastName}`;
    },

    [AppGetterTypes.signedIn]: (state: AppState) =>
        state.account !== null && state.account !== undefined,

    [AppGetterTypes.isAuthorized]: (state: AppState) =>
        state.user !== null && state.user.role === "Admin",

    [AppGetterTypes.validationRules]: (state: AppState) => {
        return state.settings ? state.settings.validationRules : null;
    },

    [AppGetterTypes.emailTemplateTags]: (state: AppState) => {
        return state.settings ? state.settings.emailTemplateDataTags : null;
    },

    [AppGetterTypes.fileUploadTypes]: (state: AppState) => {
        return state.settings ? state.settings.fileUploadTypes : null;
    }
};
