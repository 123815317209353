import { MsalService } from "@elite/msal";

import { handleSignInRedirect } from "./handleSignInRedirect";
import { msalConfig } from "./msalConfig";
import { policyConfig } from "./policyConfig";

export const msalService = new MsalService(
    msalConfig,
    policyConfig,
    handleSignInRedirect
);

export { policyConfig, handleSignInRedirect };
