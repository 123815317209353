import {
    createRouter,
    createWebHistory,
    RouteComponent,
    RouteRecordRaw
} from "vue-router";

import Home from "@/views/Home.vue";

import { routeNames } from "./routeNames";
import { routePaths } from "./routePaths";

const homeRoute: RouteRecordRaw = {
    path: "/",
    name: routeNames.home,
    component: Home,
    meta: {
        title: "Home",
        breadcrumbs: []
    }
};

const communityGalleryImageRoute: RouteRecordRaw = {
    path: routePaths.communityGalleryImageManagementPath,
    name: routeNames.communityGalleryImage,
    meta: {
        title: "Community Image Management",
        breadcrumbs: [homeRoute]
    },
    props: (r) => ({
        communityGalleryImageId: Number(r.params.communityGalleryImageId)
    }),
    component: (): Promise<RouteComponent> =>
        import("@/views/CommunityGalleryImageManagement.vue")
};

const createCommunityGalleryImageRoute: RouteRecordRaw = {
    path: routePaths.createCommunityGalleryImageManagementPath,
    name: routeNames.createCommunityGalleryImage,
    meta: {
        title: "Create Community Image",
        breadcrumbs: [homeRoute, communityGalleryImageRoute]
    },
    props: () => ({ communityGalleryImageId: 0 }),
    component: (): Promise<RouteComponent> =>
        import("@/views/EditCommunityGalleryImage.vue")
};

const editCommunityGalleryImageRoute: RouteRecordRaw = {
    path: routePaths.editCommunityGalleryImageManagementPath,
    name: routeNames.editCommunityGalleryImage,
    meta: {
        title: "Edit Community Image",
        breadcrumbs: [homeRoute, communityGalleryImageRoute]
    },
    props: (r) => ({
        communityGalleryImageId: Number(r.params.communityGalleryImageId)
    }),
    component: (): Promise<RouteComponent> =>
        import("@/views/EditCommunityGalleryImage.vue")
};

const courseManagementRoute: RouteRecordRaw = {
    path: routePaths.courseManagementPath,
    name: routeNames.courseManagement,
    meta: {
        title: "Course Management",
        breadcrumbs: [homeRoute]
    },
    component: (): Promise<RouteComponent> =>
        import("@/views/CourseManagement.vue")
};

const cancellationBlackoutDateRoute: RouteRecordRaw = {
    path: routePaths.cancellationBlackoutDatePath,
    name: routeNames.cancellationBlackoutDate,
    meta: {
        title: "Cancellation Blackout Dates",
        breadcrumbs: [homeRoute]
    },
    component: (): Promise<RouteComponent> =>
        import("@/views/CancellationBlackoutDate.vue")
};

const createCancellationBlackoutDateRoute: RouteRecordRaw = {
    path: routePaths.createCancellationBlackoutDatePath,
    name: routeNames.createCancellationBlackoutDate,
    meta: {
        title: "Add Cancellation Blackout Date",
        breadcrumbs: [homeRoute, cancellationBlackoutDateRoute]
    },
    props: (r) => ({ cancellationBlackoutDateId: 0 }),
    component: (): Promise<RouteComponent> =>
        import("@/views/EditCancellationBlackoutDate.vue")
};

const editCancellationBlackoutDateRoute: RouteRecordRaw = {
    path: routePaths.editCancellationBlackoutDatePath,
    name: routeNames.editCancellationBlackoutDate,
    meta: {
        title: "Edit Cancellation Blackout Date",
        breadcrumbs: [homeRoute, cancellationBlackoutDateRoute]
    },
    props: (r) => ({
        cancellationBlackoutDateId: Number(r.params.cancellationBlackoutDateId)
    }),
    component: (): Promise<RouteComponent> =>
        import("@/views/EditCancellationBlackoutDate.vue")
};

const createCourseRoute: RouteRecordRaw = {
    path: routePaths.createCoursePath,
    name: routeNames.createCourse,
    meta: {
        title: "Add Course",
        breadcrumbs: [homeRoute, courseManagementRoute]
    },
    props: (r) => ({ courseId: 0 }),
    component: (): Promise<RouteComponent> => import("@/views/EditCourse.vue")
};

const editCourseRoute: RouteRecordRaw = {
    path: routePaths.editCoursePath,
    name: routeNames.editCourse,
    meta: {
        title: "Edit Course",
        breadcrumbs: [homeRoute, courseManagementRoute]
    },
    props: (r) => ({ courseId: Number(r.params.courseId) }),
    component: (): Promise<RouteComponent> => import("@/views/EditCourse.vue")
};

const progressLevelRoute: RouteRecordRaw = {
    path: routePaths.progressLevelPath,
    name: routeNames.progressLevel,
    meta: {
        title: "Progress Level Management",
        breadcrumbs: [homeRoute]
    },
    props: (r) => ({ progressLevelId: Number(r.params.progressLevelId) }),
    component: (): Promise<RouteComponent> =>
        import("@/views/ProgressLevelManagement.vue")
};

const editProgressLevelRoute: RouteRecordRaw = {
    path: routePaths.editProgressLevelPath,
    name: routeNames.editProgressLevel,
    meta: {
        title: "Edit Progress Level",
        breadcrumbs: [homeRoute, progressLevelRoute]
    },
    props: (r) => ({ progressLevelId: Number(r.params.progressLevelId) }),
    component: (): Promise<RouteComponent> =>
        import("@/views/EditProgressLevel.vue")
};

const createProgressLevelRoute: RouteRecordRaw = {
    path: routePaths.createProgressLevelPath,
    name: routeNames.createProgressLevel,
    meta: {
        title: "Create Progress Level",
        breadcrumbs: [homeRoute, progressLevelRoute]
    },
    props: () => ({ progressLevelId: 0 }),
    component: (): Promise<RouteComponent> =>
        import("@/views/EditProgressLevel.vue")
};
const createLessonRoute: RouteRecordRaw = {
    path: routePaths.createLessonPath,
    name: routeNames.createLesson,
    meta: {
        title: "Add Lesson",
        breadcrumbs: [homeRoute, courseManagementRoute, editCourseRoute]
    },
    props: (r) => ({
        courseId: Number(r.params.courseId),
        lessonId: 0
    }),
    component: (): Promise<RouteComponent> => import("@/views/EditLesson.vue")
};

const editLessonRoute: RouteRecordRaw = {
    path: routePaths.editLessonPath,
    name: routeNames.editLesson,
    meta: {
        title: "Edit Lesson",
        breadcrumbs: [homeRoute, courseManagementRoute, editCourseRoute]
    },
    props: (r) => ({
        courseId: Number(r.params.courseId),
        lessonId: Number(r.params.lessonId)
    }),
    component: (): Promise<RouteComponent> => import("@/views/EditLesson.vue")
};

const createQuizRoute: RouteRecordRaw = {
    path: routePaths.createQuizPath,
    name: routeNames.createQuiz,
    meta: {
        title: "Add Quiz",
        breadcrumbs: [
            homeRoute,
            courseManagementRoute,
            editCourseRoute,
            editLessonRoute
        ]
    },
    props: (r) => ({
        courseId: Number(r.params.courseId),
        lessonId: Number(r.params.lessonId),
        quizId: 0
    }),
    component: (): Promise<RouteComponent> => import("@/views/EditQuiz.vue")
};

const editQuizRoute: RouteRecordRaw = {
    path: routePaths.editQuizPath,
    name: routeNames.editQuiz,
    meta: {
        title: "Edit Quiz",
        breadcrumbs: [
            homeRoute,
            courseManagementRoute,
            editCourseRoute,
            editLessonRoute
        ]
    },
    props: (r) => ({
        courseId: Number(r.params.courseId),
        lessonId: Number(r.params.lessonId),
        quizId: Number(r.params.quizId)
    }),
    component: (): Promise<RouteComponent> => import("@/views/EditQuiz.vue")
};

const emailTemplateManagementRoute: RouteRecordRaw = {
    path: routePaths.emailTemplateManagementPath,
    name: routeNames.emailTemplateManagement,
    meta: {
        title: "Email Template Management",
        breadcrumbs: [homeRoute]
    },
    component: (): Promise<RouteComponent> =>
        import("@/views/EmailTemplateManagement.vue")
};

const editEmailTemplateRoute: RouteRecordRaw = {
    path: routePaths.editEmailTemplatePath,
    name: routeNames.editEmailTemplate,
    meta: {
        title: "Edit Email Template",
        breadcrumbs: [homeRoute, emailTemplateManagementRoute]
    },
    props: (r) => ({ id: Number(r.params.id) }),
    component: (): Promise<RouteComponent> =>
        import("@/views/EditEmailTemplate.vue")
};

const emailsSentRoute: RouteRecordRaw = {
    path: routePaths.emailsSentPath,
    name: routeNames.emailsSent,
    meta: {
        title: "Emails Sent",
        breadcrumbs: [homeRoute]
    },
    component: (): Promise<RouteComponent> => import("@/views/EmailsSent.vue")
};

const createCancellationOfferRoute: RouteRecordRaw = {
    path: routePaths.createCancellationOfferPath,
    name: routeNames.createCancellationOffer,
    meta: {
        title: "Add Cancel/Save Offer",
        breadcrumbs: [homeRoute, courseManagementRoute, editCourseRoute]
    },
    props: (r) => ({
        courseId: Number(r.params.courseId),
        offerId: 0
    }),
    component: (): Promise<RouteComponent> =>
        import("@/views/EditCancellationOffer.vue")
};

const editCancellationOfferRoute: RouteRecordRaw = {
    path: routePaths.editCancellationOfferPath,
    name: routeNames.editCancellationOffer,
    meta: {
        title: "Edit Offer",
        breadcrumbs: [homeRoute, courseManagementRoute, editCourseRoute]
    },
    props: (r) => ({
        courseId: Number(r.params.courseId),
        offerId: Number(r.params.offerId)
    }),
    component: (): Promise<RouteComponent> =>
        import("@/views/EditCancellationOffer.vue")
};

const cancellationOfferPreviewRoute: RouteRecordRaw = {
    path: routePaths.cancellationOfferPreviewPath,
    name: routeNames.cancellationOfferPreview,
    meta: {
        title: "Save/Cancel Offer Preriew",
        breadcrumbs: [
            homeRoute,
            courseManagementRoute,
            editCourseRoute,
            editCancellationOfferRoute
        ]
    },
    props: (r) => ({
        offerId: Number(r.params.offerId)
    }),
    component: (): Promise<RouteComponent> =>
        import("@/views/CancellationOfferPreview.vue")
};

const routes: Array<RouteRecordRaw> = [
    homeRoute,
    communityGalleryImageRoute,
    createCommunityGalleryImageRoute,
    editCommunityGalleryImageRoute,
    cancellationBlackoutDateRoute,
    createCancellationBlackoutDateRoute,
    editCancellationBlackoutDateRoute,
    courseManagementRoute,
    createCourseRoute,
    editCourseRoute,
    createLessonRoute,
    editLessonRoute,
    createQuizRoute,
    editQuizRoute,
    emailTemplateManagementRoute,
    editEmailTemplateRoute,
    emailsSentRoute,
    progressLevelRoute,
    editProgressLevelRoute,
    createCancellationOfferRoute,
    editCancellationOfferRoute,
    cancellationOfferPreviewRoute,
    createProgressLevelRoute,
    {
        path: "/error-test",
        component: (): Promise<RouteComponent> =>
            import("@/views/ErrorTest.vue")
    }
];

export const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition): ScrollOptions {
        // https://router.vuejs.org/guide/advanced/scroll-behavior.html
        return { x: 0, y: 0 } as ScrollOptions;
    }
});

export { routeNames } from "./routeNames";
export { routePaths } from "./routePaths";
