
import { MenuItem } from "@/models";
import { defineComponent, PropType } from "vue";

export default defineComponent({
    props: {
        menuItem: {
            type: Object as PropType<MenuItem>,
            required: true
        }
    }
});
