import { Configuration } from "@azure/msal-browser";

export const msalConfig: Configuration = {
    auth: {
        authority: process.env.VUE_APP_AAD_AUTHORITY,
        clientId: process.env.VUE_APP_AAD_CLIENT_ID,
        redirectUri: process.env.VUE_APP_AAD_REDIRECT_URI,
        postLogoutRedirectUri: process.env.VUE_APP_AAD_REDIRECT_URI,
        knownAuthorities: [process.env.VUE_APP_AAD_AUTHORITY]
    },
    cache: { cacheLocation: process.env.VUE_APP_AAD_CACHE_LOCATION }
};
